import _objectSpread from "/workspace/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable global-require */
import illustration from '@/assets/images/illustrations/api.svg';
import FirebaseService from '@/services/firebase.service';
import { mapGetters } from 'vuex';
export default {
  name: 'NotAdminVue',
  data: function data() {
    return {
      illustration: illustration
    };
  },
  computed: _objectSpread({}, mapGetters({
    user: 'user/me'
  })),
  watch: {
    user: function user(value) {
      if (value.isAdmin) {// this.$router.push('/')
      }
    }
  },
  methods: {
    logout: function logout() {
      FirebaseService.signOut();
    }
  }
};